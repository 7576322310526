/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {

    .main-content {
        margin: 20px 1% 20px 5%;
    }

    .top-content {
        margin: 0 10% 0 5%;
    }

    .section-active-status-bar,
    .section-status-bar {
        left: 46px;
    }

    .yellow-arrow-icon,
    .grey-arrow-icon {
        display: none;
    }

    .section-name{
        margin-left: 36px;
    }

    .startapp-action-button{
        transform: none;
    }
    /*login*/
    .login-form-content {
        width: 100%;
    }
    .login-heading-blue > span {
      font-size: 64px;
    }
    .login-heading-blue-smart > span {
      font-size: 34px;
    }
    .login-heading-white > span {
      font-size: 36px;
    }
    .form-input-login {
      height: 50px;
      font-size: 16px !important;
    }
    .form-input-login + .error-message {
      font-size: 15px;
    }
    .eye.eye-icon-pwd {
      margin-top: 0.25em;
    }
    .form-field > a {
      font-size: 1.5rem;
    }
    .ui.button.auth-button {
      font-size: 16px !important;
      height: 50px;
      width: 100%;
      border-radius: 2px !important;
    }

    /* Table Component Optimization for iPad */

    .mobile-table-componnet {
      padding: 20px;
      min-height: 70vh;
    }

    .mobile-add-button {
      right: 25px;
      margin-top: 10px;
    }

    .mobile-add-button span {
      font-size: 18px;
    }
  
    .mobile-add-button img {
      width: 20px;
      height: 20px;
    }

    /* for corp info -> ipad responsive */
    .corp-info-section .ui.form .fields,
    .dba-info-section .ui.form .fields,
    .bank-info-section .ui.form .fields,
    .owner-info-section .ui.form .fields,
    .pre-screening-info .ui.form .fields,
    .equipment-info-section .ui.form .fields,
    /* .equipment-info-section .ui.form .fields, */
    .business-info-section .ui.form .fields,
    .transaction-fields.ui.form .fields{
      flex-direction: column;
    }

    .corp-info-section .ui.form .inline.fields,
    .dba-info-section .ui.form .inline.fields,
    .bank-info-section .ui.form .inline.fields,
    .owner-info-section .ui.form .inline.fields,
    .pre-screening-info .ui.form .inline.fields,
    .equipment-info-section .ui.form .inline.fields,
    /* .equipment-info-section .ui.form .inline.fields, */
    .transaction-fields .ui.form .inline.fields{
      flex-direction: row;
      width: 90%;
    }

    .corp-info-section .ui.form .eight.wide.field,
    .dba-info-section .ui.form .eight.wide.field,
    .bank-info-section .ui.form .eight.wide.field,
    .owner-info-section .ui.form .eight.wide.field,
    /* .pre-screening-info .ui.form .eight.wide.field, */
    .equipment-info-section .ui.form .eight.wide.field,
    /* .equipment-info-section .ui.form .eight.wide.field, */
    .business-info-section .ui.form .eight.wide.field,
    .transaction-fields .ui.form .eight.wide.field{
      width: 90% !important;
      display: grid;
      grid-template-columns: 34% 65%;
      align-items: center;
      justify-content: space-between;
    }

    .corp-info-section .ui.form .eight.wide.field:not(:first-child),
    .dba-info-section .ui.form .eight.wide.field:not(:first-child),
    .bank-info-section .ui.form .eight.wide.field:not(:first-child),
    .owner-info-section .ui.form .eight.wide.field:not(:first-child),
    .pre-screening-info .ui.form .eight.wide.field:not(:first-child),
    .equipment-info-section .ui.form .eight.wide.field:not(:first-child),
    /* .equipment-info-section .ui.form .eight.wide.field:not(:first-child), */
    .business-info-section .ui.form .eight.wide.field:not(:first-child),
    .transaction-fields.ui.form .eight.wide.field:not(:first-child){
      margin-top: 14px;
    }

    .pre-screening-info .ui.form .twelve.wide.field{
      width: 90% !important;
      display: grid;
      grid-template-columns: 30% 68%;
      justify-content: space-between;
    }

    .corp-info-section .ui.form .fields input,
    .dba-info-section .ui.form .fields input,
    .bank-info-section .ui.form .fields input,
    .owner-info-section .ui.form .fields input,
    .pre-screening-info .ui.form .fields input,
    .equipment-info-section .ui.form .fields input,
    /* .equipment-info-section .ui.form .fields input, */
    .business-info-section .ui.form .fields input,
    .transaction-fields .ui.form .fields input{
      min-height: 42px;
    }
    .corp-info-section .ui.form .fields input,
    .dba-info-section .ui.form .fields input,
    .bank-info-section .ui.form .fields input,
    .owner-info-section .ui.form .fields input,
    .pre-screening-info .ui.form .fields input,
    .equipment-info-section .ui.form .fields input,
    /* .equipment-info-section .ui.form .fields input, */
    .business-info-section .ui.form .fields input,
    .transaction-fields .ui.form .fields input{
      display: inline-block !important;
    }

    /* .info1-icon{
      display: none;
    } */

    .startapp .ui.form .eight.wide.field{
      width: 90% !important;
    }

    .startapp .business-info-section .ui.form .eight.wide.field{
      width: 100% !important;
    }
  
    .pricing-section .other-table.ui.structured.celled.table tr{
    display: grid;
    grid-template-columns: 50% 50%;
    width: 80%;
  }

    .mobile-table thead th {
      max-width: 120px;
      word-wrap: break-word;
      font-weight: 400;
    }

    .mobile-table tbody td {
      max-width: 120px;
      word-wrap: break-word;
    }

  .equipment-info-section .ui.form .sixteen.wide.field{
    width: 90% !important;
    display: grid;
    grid-template-columns: 34% 65%;
    align-items: center;
    justify-content: space-between;
  }

  .mob-audit-heading-filter {
    flex-wrap: nowrap !important;
    align-items: flex-start !important;
    margin-top: 0px;
  }

  .mob-audit-filter {
    flex-wrap: wrap;
    margin: 0;
    align-items: flex-start !important;
  }

  .heading .date-filter:first-child {
    width: 95%;
    margin: 0 0 12px 0;
  }

  .heading .date-filter:first-child input{
    margin-left: -5px;
  }

  .mob-audit-heading-filter .heading-text-left {
    width: 155px;
    margin-top: 5px;
  }

  .heading .audit-btn {
    display: inline-flex;
    margin-top: 46px;
  }

  .eye-icon-owner-dlId .ipad-eye-icon{
    right: 12%;
    margin-bottom: 16px !important;
  }
  
  i.eye.large.disabled.icon.eye-icon-owner-dlId.ipad-eye-icon{
    right: 12%;
    margin-bottom: 16px !important;
  }
    
  i.eye.large.icon.eye-icon-owner-dlId.ipad-eye-icon{
    right: 12%;
    margin-bottom: 16px !important;
  }

  .ant-pagination{
    padding-right: 20px;
   }

   .ant-input-group{
    padding: 14px 20px 14px;
   }
}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
    .top-content {
        margin: 0 15% 0 20%;
    }

    .section-active-status-bar,
    .section-status-bar {
        left: 46px;
    }

    .yellow-arrow-icon,
    .grey-arrow-icon {
        display: none;
    }

    .section-name{
        margin-left: 36px;
    }

    .main-content{
        margin: 20px 4% 20px 20%;
    }

    /* Table Component Optimization for iPad */

    .mobile-table-componnet {
      padding: 30px;
      min-height: 70vh;
    }

    .mobile-table thead th {
      max-width: 150px;
      word-wrap: break-word;
      font-weight: 400;
    }

    .mobile-add-button {
      right: 35px;
      margin-top: 15px;
    }

    .mobile-add-button span {
      font-size: 20px;
    }
  
    .mobile-add-button img {
      width: 25px;
      height: 25px;
    }

    .mobile-table tbody td {
      max-width: 150px;
      word-wrap: break-word;
    }
    .pricing-section .other-table.ui.structured.celled.table tr{
      display: grid;
      grid-template-columns: 25% 25% 25% 25%;
    }

    .business-info-section .ui.form .eight.wide.field:not(:first-child) {
      margin-top: 14px;
    }

    .pre-screening-info .ui.form .twelve.wide.field{
      width: 90% !important;
      display: grid;
      grid-template-columns: 30% 68%;
      justify-content: space-between;
    }

    .ant-pagination{
      padding-right: 30px;
     }

     .ant-input-group{
      padding: 14px 30px 14px;
     }

  .applist-container .bulkupdate-action{
    margin-bottom: -30px;
  }

}

@media 
only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: landscape),
only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (-webkit-min-device-pixel-ratio: 1)  and (orientation: portrait){

    .footer-logo{
     left: 20px !important;
     position: relative;
     min-width: 150px;
   }
   
   .footer-p{
     width: 90%;
     text-align: start;
     font-size: 12px;
     margin-top: 15px;
   }
   span.mob-privacy{
     display: block;
   }
   .footerLogoArea{
     width: 100%;
     height: 120px;
   }
   .logo{
     margin-left: 20px;
     min-width: 130px;
   }
   .header{
     height: 80px;
     width:100%
     /* height: 80px */
   }
   ul.ipad-header-btn{
     display: flex;
     align-items: center;
     margin-left: 13px !important;
   }
   
   li.header-ipad-btns a{
     height: 30px;
     width:90px;
     padding: 5px 5px;
     font-size: 12px;
     margin: 0px 5px;
     border-radius: 5px;
   }
   
   .iris_button a, .fn_button{
     display: none;
   }
   .user-name{
     display: none;
   }
   i.power.big.icon.logout-icon {
     display: none;
   }
   .mobile-menu{
     display: block;
     margin-left: 3px;
   }
   
   .header .mobile-menu .hamburger-icon {
     width: 34px;
     height: 34px;
     display: flex;
     flex-direction: column;
     justify-content: space-between;
   }
   .filter-header-mob{
     border: 1px solid white;
     border-radius: 5px;
     padding: 4px;
   }
   .mob-name-role {
     display: flex;
     flex-direction: column;
     font-size: 16px;
     padding-left: 20px;
     padding-top: 2px;
   }
   span.circle-name {
     padding: 10px;
     margin-left: 10px;
     border: 1px solid white;
     color: #2B7FDA;
     border-radius: 50%;
     background-color: white;
     text-align: center;
     padding-top: 10px;
     margin-right: 2px;
     
   }
   span.ham-name {
     color: white;
     background-color: #2B7FDA;
     padding: 10px;
     width: 20px;
     font-size: 20px;
   }
    .right-logout{
     display: none;
    }
   
    /* .mobile-menu-sidebar {
     width: 280px;
     position: absolute;
     z-index: 1000;
     top: 0;
     right: 0;
     background-color: white;
     padding: 4px 0;
   } */

  .mobile-menu-sidebar {
    height: 110vh;
    position: absolute;
    z-index: 1000;
    top: 0;
    right: 0;
    background-color: white;
    padding: 4px 0;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
  }

  .mobile-menu-sidebar-width-open {
    width: 280px;

  }

  .mobile-menu-sidebar-width-closed {
    width: 0px;
  }

  .active-mobile {
    background-color: #2B7FDA;
    color: white !important;
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    border-left: 5px solid #D9D9D9;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
   
   .mobile-menu-sidebar .back-button-container {
     display: flex;
     align-items: center;
     font-size: 20px;
     font-weight: 500;
     padding: 15px 15px;
     color: black;
   }
   
   .mobile-menu-sidebar .back-button-container svg {
     width: 32px;
     height: 36px;
     margin-right: 10px;
   }
   
   .mobile-menu-sidebar .mobile-menu-items {
     padding: 30px 30px;
   }
   
   .mobile-menu-sidebar .mobile-menu-items a {
     display: block;
     padding: 1rem;
     color: #2B7FDA;
   }
   
   .profile-name {
     background: #2B7FDA;
     color: white;
     padding: 15px 5px;
     margin-left: -30px;
     width: 280px;
     display: flex;
   }
   .appid-order {
     color: #2B7FDA;
     padding: 10px;
     display: block;
   }
   .mobile-menu-sidebar .mobile-menu-items a:hover{
    color: white;
    background-color: #2B7FDA;
  }

  .green-arrow-icon{
    display: none;
  }

  .section-name.text-success{
    color: #38BE3D;
  }
   
  .message-section-icon{
    display: none;
  }

  .form-headingleftpadding{
    padding: 14px 20px 0;
  }

  .left-content{
    width: 31%;
  }

  .right-content{
    width: 73%;
  }

  .ui.checkbox .box, .ui.checkbox label{
    padding-left: 22px;
  }

  .ui.form .flex-container{
    display: flex;
    justify-content: space-between;    
  }

  .ui.form .flex-container .four.wide.field{
    width: auto !important;
  }

  .save-button-right{
    position: absolute;
    margin-top: -6px !important;
    right: 22px !important;
    margin-right: 0 !important;
  }

  .navigationright-icon,.navigationleft-icon{
    display: none;
  }

  .form-headingleftpadding{
    position: relative;
  }

  .ui.form .fields.MD-FDC{
    flex-direction: column;
  }

  .startapp-action-button{
    transform: none;
  }

  .tab-content-footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .tab-content-footer button:last-child{
    margin-right: 0 !important;
  }

  li.ipad-btns a{
    display: none;
  }

  .mobile-menu-sidebar .back-button-container{
    display: none;
  }

  .mobile-menu-sidebar .mobile-menu-items{
    padding: 0px 30px;
    margin-top: -4px;
  }

  .mobile-menu-sidebar{
    margin-top: 80px;
  }

  .footer-copyright{
    display: grid;
    grid-template-columns: 50% 50%;
    width: 100%;
  }

  .footer-text{
    margin-top: -10px;
  }
  .footer-blockArea {
  margin: 10px;
  width: 96%;
  }

  span.mob-privacy{
   margin-top: -12px;
  }

  .blockArea{
    display: flex;
    width: 100%;
  }

  span.circle-name.ipad-circle {
    display: none;  
  }

  .grey-outline{
    border: none;
  }

  .pricing-section .ui.structured.celled.table tr td, .pricing-section .ui.structured.celled.table tr th{
    background-color: #f3f3f3;
  }

  .input-dollar{
    padding-top: 8px;
    background-color: #2B7FDA;
    color: white;
    border-radius: 5px 0 0 5px;
  }

  .form-contents .ui.structured.table{
    background-color: #f3f3f3;
  }

  .form-contents .ui.structured.table{
    background-color: #f3f3f3;
  }

  /* .form-contents .ui.structured.table.pricing-table.qual th, .form-contents .ui.structured.table.pricing-table.qual tr{
    grid-template-columns: 29% 35% 35%;
  } */

  .form-contents .pricing-table td input{
    background-color: #f3f3f3;
  }

  .startapp .ui.form:not(.unstackable) .fields:not(.unstackable)>.eight.wide.field .input-dollar+input[type=text], .startapp .ui.form .input-dollar+input[type=text], .startapp .ui.form:not(.unstackable) .fields:not(.unstackable)>.eight.wide.field .input-dollar+input[type=tel], .startapp .ui.form .input-dollar+input[type=tel]{
    padding-left: 38px !important;
  }
  
  /* .top-content{
    margin: 0 10% 0 10%;
  } */

  .heading-text-left.docs-upload-ipad{
    font-size: 20px;
    font-family:"Montserrat";
  }

  .left-heading {
    font-size: 16px;
    font-family: "Montserrat";
    margin-top: -20px;
  }

  .dropzone-title{
    font-family: "Montserrat";
  }

  .drop-container{
    margin: 10px 0 20px;
    position: relative;
    height: 75px;
    display: flex;
  }

  .drop-container .docupload-icon{
    margin: 0;
    right: 10px;
    top: 15px;
    background: transparent url("../../../Images/upload-icon2.svg") no-repeat center;
  }

  .drop-container .drop-message{
    margin: 0;
  }

  .drop-container .drop-message .dropzone{
    border: none;
  }

  .body-content p{
    padding-right: 200px;
    font-size: 14px;
  }
  
  .ui.inverted.blue.button, .ui.inverted.blue.buttons .button {
    background: #E1E2E2;
    font-weight: 700px !important;
    border-radius: 10px;
  }

  .ui.modal .actions>.button{
    background: #E1E2E2;
    font-weight: 700px !important;
    border-radius: 10px !important;
    color: #2B7FDA;
  }

  .ui.form .three.wide.field{
    width: 30% !important;
  }

  .ui.form .inline.fields{
    margin: 0 0 0.5em;
  }

  .center-buttons.ipad-center-buttons{
    display: none !important;
  }

  /* i.large.icon, i.large.icons{
    display: none;
  } */

  
  .overlay-layer {
    width: 100vw;
    height: 110vh;
    position: fixed;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.80);
    top: 0;
    right: 0;
  }

  /* Table Component Optimization for iPad */

  .table-actions-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .table-actions-wrapper .table-actions-mob {
    border-radius: 13px;
    width:100%;
    max-width: 350px;
    background-color: white;
    color:black;
    position: relative;
    margin: 0;
  }

  .table-actions-wrapper .table-actions-mob > button {
    border-bottom:0.5px solid rgba(141, 141, 141, 0.5);
    box-sizing: border-box;
    padding:10px 20px;
    text-align: center;
    width: 100%;
    max-width: 350px;
    background-color:white;
    font-size: 17px;
  }

  .table-actions-wrapper .table-actions-mob .top-radius {
    border-radius: 13px 13px 0 0;
  }

  .table-actions-wrapper .table-actions-mob .bottom-radius {
    border-radius: 0 0 13px 13px;
    border: none;
  }

  .row-details-table div {
    grid-template-columns: 50% 95%;
  }

  .table-heading-container {
    margin-bottom: 20px;
  }
  .business-info-section .ui.form .fields div.ui.checkbox{
    margin-left: 1rem;
  }
  
  .business-info-section .ui.form .inline.fields:not(.seasonal-month-wrapper){
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }

  .business-info-section .ui.form .inline.fields.seasonal-month-wrapper{
    flex-direction: row;
  }
  
  .business-info-section .ui.form .fields{
    flex-direction: column;
  }

  .business-info-section .ui.form .eight.wide.field{
    width: 100% !important;
  }

  .ui.form .field>label, .ui.form .inline.fields>label{
    font-weight: normal;
  }

  .input-percent{
    background-color: #2B7FDA;
    color: white;
    padding-top: 6px;
  }

  .warning-text{
    font-size: 1rem;
  }


  .pricing-section .ui.structured.celled.table tr td, .pricing-section .ui.structured.celled.table tr th{
    width: 100%;
  }

  .pricing-section .ui.structured.celled tr{
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    align-items: center;
  }

  .border-left-field{
    border-left: none !important;
  }

  .ui.form .eight.wide.field.gdt-100{
    grid-template-columns: 100%;
  }

  .ui.form .eight.wide.field.gdt-100 .inline.fields{
    width: 100%;
    justify-content: space-between;
  }

  .ui.form .eight.wide.field.gdt-100 .inline.fields > div{
    display: flex;
  }

  .ui.form .eight.wide.field.gdt-100 .inline.fields div.checkbox-inline{
    margin-left: 1rem;
  }

  .startapp .transaction-fields.ui.form .eight.wide.field{
    display: grid;
    grid-template-columns: 30% 68%;
    justify-content: space-between;
    width: 80% !important;
  }

  .transaction-fields.ui.form .fields{
    flex-direction: column;
  }

  .transaction-fields.ui.form .eight.wide.field:not(:first-child){
    margin-top: 14px;
  }

  .mb-14{
    margin-bottom: 14px !important;
  }

  .pre-screening-info .ui.form .inline.fields{
    margin-bottom: 14px;
  }
  
  .pre-screening-info .ui.form .twelve.wide.field:not(:first-child){
    margin-top: 14px;
  }

  .ui.form .inline.fields .field>label{
    font-weight: normal;
  }

  .equipment-info-section .ui.form .fields.grid-align-equip.check-box{
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .equipment-info-section .ui.form .fields.grid-align-equip.check-box .eight.wide.field{
    grid-template-columns: 100%;
  }
  
  .ui.inverted.blue.button, .ui.inverted.blue.buttons .button{
    margin-right: 8px;
  }

  .owner-info-section .ui.inverted.blue.button, .owner-info-section .ui.inverted.blue.buttons .button, 
  .equipment-info-section .ui.inverted.blue.button, .equipment-info-section .ui.inverted.blue.buttons .button{
    border-radius: unset !important;
  }

  .tab-flex-button{
    flex-wrap: wrap;
  }

  .tab-flex-button > div{
    margin-bottom: 1em;
  }

  .fields .no-bold{
    font-weight: normal;
  }

  .ui.structured.celled tr.gdt-50-50{
    grid-template-columns: 50% 50%;
  }

  .billing-month-wrapper{
    grid-template-columns: 50% 50%;
  }
  
  /* Audit Filter Optimization */

  .mob-audit-heading-filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 20px 20px 0 20px;
  }

  .mob-audit-heading-filter .heading-text-left {
    font-size: 17px;
  }

  .mob-audit-filter {
    justify-content: flex-start;
  }

  .mob-audit-filter .field {
    margin-right: 5px;
    width: 120px;
  }

  .mob-audit-filter .field input {
    width: 100%;
  }

  .mob-audit-filter .button-container button {
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    min-width: 90px;
    font-size: 11px !important;
    background-color: transparent !important;
    color: #2185d0 !important;
  }

  .heading .date-filter {
    margin-right: 5px;
  }
  
  .heading .date-filter label {
    margin-right: 10px;
  }

  .heading .audit-btn button {
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    min-width: 90px;
    font-size: 11px !important;
  }

  .ui.modal>.close {
    cursor: pointer;
    position: absolute;
    top: -2rem;
    right: -2rem;
    z-index: 1;
    opacity: .8;
    font-size: 1.25em;
    color: #fff;
    width: 2.25rem;
    height: 2.25rem;
    padding: 0.625rem 0 0 0;
  }

  .mobile-table-list {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 60vh;
  }

  .userslist-container .mobile-table-componnet .table-heading-container {
    display: flex !important;
  }

  .month-check-box {
    margin-bottom: 1rem !important;
  }

  /* .month-check-box > label{
    display: none !important;
  } */

  .sidebar-search {
    border:1px solid black;
    border-radius: 3px;
    margin-top: 10px;
    width: 100%;
    height: 30px;
    padding: 10px;
  }
  .search-buttons {
    margin: 7px;
    display: flex;
    justify-content: space-between;
  }
  .search-buttons button {
    min-width: 100px;
    padding:5px;
    color: white;
    background-color: #2B7FDA;
    border-radius: 5px;
    font-size: 1rem;
    border:1px solid #2B7FDA;
    font-family: Montserrat;
  }

  input.sidebar-search{
    width: 100%;
    height: 40px;
    border-radius: 10px;
    border: 2px solid #979797;
    }

    .fill-width{
      width: 70%;
    }

    p.value-edit{
      margin-right: 5px;
      font-size: 12px;
    }

    p.value-delete{
      font-size: 12px;
    }

    button.ui.red.button {
      color: white !important;
      background-color: red !important;
   }

    button.ui.green.button{
      background-color: rgb(70, 194, 70) !important;
      color: white !important;
    }

    button.ui.blue.button.ipad-blue{
      background-color: #2185d0;
      color: white;
    }

    p.value-update{
      font-size: 9px;
      margin-right: 2px;
    }
  
    p.value-cancel{
      font-size: 9px;
    }

    .Value-box.mobile-value-box{
      display: block;
    }
  
    button.ui.blue.button.mobile-add-field,
    button.ui.blue.disabled.button.mobile-add-field{
      margin-top: 10px !important;
      margin-left: 40px !important;
    }

    .heading-center{
      font-size: 18px;
    }

    span.heading-center.mobile-user-list {
      display: none;
    }

    button.ui.button.cancel-mob-field{
      margin-top: 10px;
    }
    
    .search-mob{
      display: inline-block;
      width: 100%;
      }

  .applist-container{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .applist-container .bulkupdate-action{
    float:none;
    width: 50%;
  }
  .menu{
    width: 50%;
  }
  .navbar.merchant-navbar{
    width: 100%;
    margin: 0px 5px;
  }

}