.mobile-menu{
  display: none;
}
.hide-sidebar-data{
  display: none;
}

@media only screen and (max-width: 767px) {
  .container {
    max-height: 98vh;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
  }
  .container::-webkit-scrollbar{
    display: none;
  }

  .mobile-menu{
      display: block;
  }
    .header {
        width: calc(100% - 16px);
        margin: 8px;
        display: flex;
        justify-content: space-between;
        padding: 0 10px;
    }

    .header .logo {
        min-width: 100px;
        /* background: url(../../../Images/genesis-logo-short.png) no-repeat center center; */
        background-size: 100px;
    }

    .header .mobile-menu .hamburger-icon {
        width: 34px;
        height: 34px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .header .menu-items {
        display: none;
    }

    .header .mobile-menu svg {
        fill: white;
        width: 30px;
        height: 30px;
    }

    .mobile-menu-sidebar {
      height: 110vh;
      position: absolute;
      z-index: 1000;
      top: 0;
      right: 0;
      background-color: white;
      padding: 4px 0;
      transition: all 0.3s ease-in-out;
      overflow: hidden;
    }

    .mobile-menu-sidebar-width-open {
      width: 280px;
  
    }
  
    .mobile-menu-sidebar-width-closed {
      width: 0px;
    }

    .mobile-menu-sidebar .back-button-container {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 500;
        padding: 0 30px;
        color: black;
    }

    .mobile-menu-sidebar .back-button-container svg {
        width: 32px;
        height: 36px;
        margin-right: 10px;
    }

    .mobile-menu-sidebar .mobile-menu-items {
        padding: 30px 30px;
    }

    .mobile-menu-sidebar .mobile-menu-items a {
        display: block;
        padding: 1rem;
        color: #2B7FDA;
    }

    .overlay-layer {
      width: 100vw;
      height: 110vh;
      position: absolute;
      z-index: 999;
      background-color: rgba(0, 0, 0, 0.80);
      top: 0;
      right: 0;
    }
  
    .overlay-layer-closed {
      width: 0;
      height: 0;
    }

    .login-content {
        width: calc(100% - 50px);
    }

    .login-content .login-form-content {
        width: 100%;
    }

    .logo-login {
        /* background: url(../../../Images/genesis-logo-short.png) no-repeat; */
        background-size: 170px;
        left: 25px;
        margin-top: 25px;
    }

    .login-heading-white {
        margin-bottom: 2rem;
    }

    .login-form-content .form-input-login{
        border-radius: 5px !important;
        height: 50px;
        padding: 1rem 30px !important;
    }

    .login-form-content .form-input-login::placeholder{
        text-transform: capitalize;
    }

    .login-content .ui.button{
        width: 100%;
        border-radius: 5px !important;
        box-shadow: none !important;
        height: 50px;
    }
    
    .eye-icon-pwd{
        padding-top: 12px !important;
    }
    /* startApp */
    /* mobile responsive */
.main-content {
    margin:1rem;
    float: none;
  }
  
  .left-content {
    margin-left: 0;
    width:100%;
    float: none;
  }
  
  .right-content {
    display: none;
  }
  
  .mobile-rsp > .yellow-arrow-icon,
  .mobile-rsp > .grey-arrow-icon,
  .mobile-rsp > .green-arrow-icon,
  .mobile-rsp > .red-arrow-icon,
  .section-status-bar ,
  .section-active-status-bar {
   display: none;
  }
  
  .mob-section-active-status-bar {
    left:15px;
    background-color: #D9D9D9;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 20px;
  }
  
  .mobile-rsp{
    background-color: white;
    color: white;
    height: 50px;
    width: 100%;
    /* padding-top: 20px; */
    display: flex;
    align-items: center;
    border-left: 5px solid #D9D9D9;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .mobile-rsp-active{
    background-color: #2B7FDA;
    color: white;
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    border-left: 5px solid #D9D9D9;
  }
  
  .mobile-rsp > span.mob-section-name {
    color: black !important;
    margin-left: 1rem;
  }
  
  .mob-section-name-active {
    color: white !important;
    margin-left: 1rem;
  }
  
  .vertical-list-mob-block {
    padding: 0;
    margin: 0;
    width: 100%;
    margin-bottom: 12px;
    border-radius: 5px;
    border-width: 2px;
    background: #D9D9D9;
  }

  .mob-status-bar-green {
    border-left: 5px solid #38BE3D;
  }
  
  .mob-status-bar-red {
    border-left: 5px solid #CB0000;
  }
  
  .mobile-footer {
    display: flex;
    bottom: 0;
    text-align: center;
    height: 60px;
    padding: 10px;
    justify-content: center;
    z-index: 100;
    font-size: 1.3em;
  }
  
  .mob-footer-icons {
    width: 33%;
    text-align: center;
  }

  .mob-footer-icons-view {
    width: 50%;
    border-bottom: 1px solid #102234;;
    text-align: center;
  }
  
  .mob-footer-text{
    color: black;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 12px;
    padding-top: 3px;
    letter-spacing: -0.23999999463558197px;
    text-align: center;
    border-bottom: 2px solid #102234;
    height:35px;

  }

  .mob-footer-image-blue {
    background-color: #2B7FDA;
  }
  .mob-footer-cur {
    color: #2B7FDA;
    border-bottom: 2px solid #2B7FDA;
    height:35px;
  }

  .form-headingleftpadding,
  .form-heading-message {
    display: none;
  }

  .footerLogoArea > .blockArea {
    width: 98%;
  }
  .blockArea > .footer-logo {
    position: relative;
    top:5px;
  }
  .blockArea > .footer-p {
    margin:0;
    padding-bottom: 10px;
  }
  .footerCardArea > .footer-blockArea {
    float: none;
    width: 100%;
    padding: 5px;
  }
  .footer-text {
    margin-left: 0;
    margin-top:15px;
  }
  .mob-copyright {
    left:5px;
    position: relative;
  }
  .mob-privacy {
    display: flex;
    justify-content: space-around;
  }
  .mob-save-Button {
    font-family: Montserrat;
    font-size: 13px;
    width: 60px;
    height: 10px; 
  }

  .center-buttons{
    display: none;
  }

  .mob-center-buttons{
    width:100%;
    margin: 0 auto;  
    display: flex;
    margin-bottom: 15px;
    justify-content: right;
  }

  .ui.button{
    color: #2B7FDA;
    background-color: #E1E2E2;
    font-size: larger;
    border-radius: 10px;
  }

  .mob-block-button{
    position: absolute;
    width: 11px;
    height: 8px;
    left: 339px;
    top: 134px;
    color: black;
    background: #D9D9D9;
    transform: rotate(90deg);
  }

  .search{
   background: white;
   border-radius: 50px;
  }
   
  .ui.selection.dropdown >.dropdown.icon{
   background-color: transparent;
   size: 20px;
   top: 15px;
   border-radius: 5px !important;
  }

  .dropdown.icon{
    width: 50px;
    color: black;
  }
  .warning-text {
    font-size:14px;
  }
  .startapp-action-button{
   transform: none;
  }
  .startapp-action-button .save-button, 
  .startapp-action-button .save-button button {
   font-size: 14px !important;
    }
  .startapp-action-button > .ui.inverted.blue.button {
    margin-left:5px !important;
    margin-right:0px !important;
    height: 30px;
    padding:5px !important;
    min-width: 80px !important;
    font-size:8px !important;
    margin-top: 13px !important;
  }

  .top-container{
    margin: 0 1rem;
    width: calc(100% - 2rem);
    border-radius: 5px;
  }

  .top-content{
    margin: 0;
    padding: 20px 10px;
  }

  .ui.form .inline.fields .field{
    padding: 0;
  }

  .form-container{
    background-color: #F3F3F3;
    margin-bottom: 3rem;
    float: none;
  }

  hr.top{
    margin: 0;
    display: none;
  }

  .show-section-title{
    display: block;
  }

  .form-contents{
    background-color: #D9D9D9;
    margin-bottom: 0;
  }

  .ui.search.selection.dropdown>input.search{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    height: 49px;
    
  }

  .startapp .top-content-label{
    display: none !important;
  }

  .startapp .ui.default.dropdown:not(.button)>.text, .startapp .ui.dropdown:not(.button)>.default.text{
    color: #000 !important;
  }

  .startapp .form-input, .startapp .not-filled{
    height: 50px;
    color: #000 !important;
    border-radius: 5px !important;
    border: none;
    font-size: 14px !important;
  }

  .startapp .form-input::placeholder, .startapp .not-filled::placeholder{
    color: #000 !important;
  }

  .startapp .ui.search.dropdown>.text{
    top: 6px;
  }

  .startapp .ui.form .field>.selection.dropdown{
    height: 50px;
  }

  .startapp .form-fields{
    padding: 20px 10px;
    max-width: calc(100vw - 32px);
    min-width: calc(100vw - 32px);
  }

  .border-left-field, .grey-outline{
    border: none !important;
    margin: 0 !important;
  }

  .input-dollar{
    height: 50px;
    background-color: #2B7FDA;
    color: white;
  }

  .ui.selection.dropdown{
    height: 50px;
  }

  .ui.form:not(.unstackable) .fields:not(.unstackable)>.four.wide.field:not(:first-child),
  .ui.form:not(.unstackable) .fields:not(.unstackable)>.four.wide.field:not(:first-child),
  .ui.form:not(.unstackable) .fields:not(.unstackable)>.three.wide.field:not(:first-child){
    width: auto !important;
  }

  /* .ui.form:not(.unstackable) .fields:not(.unstackable)>.eight.wide.field:not(:first-child),
  .ui.form:not(.unstackable) .fields:not(.unstackable)>.eleven.wide.field:not(:first-child),
  .ui.form:not(.unstackable) .fields:not(.unstackable)>.fifteen.wide.field:not(:first-child),
  .ui.form:not(.unstackable) .fields:not(.unstackable)>.five.wide.field:not(:first-child),
  .ui.form:not(.unstackable) .fields:not(.unstackable)>.four.wide.field:not(:first-child),
  .ui.form:not(.unstackable) .fields:not(.unstackable)>.fourteen.wide.field:not(:first-child),
  .ui.form:not(.unstackable) .fields:not(.unstackable)>.nine.wide.field:not(:first-child),
  .ui.form:not(.unstackable) .fields:not(.unstackable)>.seven.wide.field:not(:first-child),
  .ui.form:not(.unstackable) .fields:not(.unstackable)>.six.wide.field:not(:first-child),
  .ui.form:not(.unstackable) .fields:not(.unstackable)>.sixteen.wide.field:not(:first-child),
  .ui.form:not(.unstackable) .fields:not(.unstackable)>.ten.wide.field:not(:first-child),
  .ui.form:not(.unstackable) .fields:not(.unstackable)>.thirteen.wide.field:not(:first-child),
  .ui.form:not(.unstackable) .fields:not(.unstackable)>.three.wide.field:not(:first-child),
  .ui.form:not(.unstackable) .fields:not(.unstackable)>.twelve.wide.field:not(:first-child),
  .ui.form:not(.unstackable) .fields:not(.unstackable)>.two.wide.field:not(:first-child),
  .ui.form:not(.unstackable) .five.fields:not(.unstackable)>.field:not(:first-child),
  .ui.form:not(.unstackable) .five.fields:not(.unstackable)>.fields,
  .ui.form:not(.unstackable) .four.fields:not(.unstackable)>.field:not(:first-child),
  .ui.form:not(.unstackable) .four.fields:not(.unstackable)>.fields,
  .ui.form:not(.unstackable) .three.fields:not(.unstackable)>.field:not(:first-child),
  .ui.form:not(.unstackable) .three.fields:not(.unstackable)>.fields,
  .ui.form:not(.unstackable) .two.fields:not(.unstackable)>.field:not(:first-child),
  .ui.form:not(.unstackable) .two.fields:not(.unstackable)>.fields {
    width: auto !important;
  } */

  .ui.form .fields{
    justify-content: space-between;
    /* margin-bottom: 1rem; */
  }

  .ui.checkbox label, .ui.radio.checkbox label{
    padding-left: 22px;
  }

  .pricing-section .ui.structured.table{
    background-color: transparent;
  }

  .pricing-table.ui.structured.celled.table tr td{
    padding: .75em 0 !important;
  }

  .ui.button.save-button.topcontainer-save-btn {
    margin-top:10px;
    margin-right: 20px;
  }
  .startapp .ui.form input[type=text], .startapp .ui.form input[type=tel]{
    padding-left: 14px !important;
    width: 100% !important;
  }

  .startapp .ui.form .selection.dropdown input[type=text], .startapp .ui.form .selection.dropdown input[type=tel]{
    padding-left: 14px !important;
  }

  .startapp .ui.search.dropdown>input.search{
    left: 0px;
  }


  .semantic-date-input .field{
    height: 50px !important;
    /* box-shadow: 0px 4px 4px rgb(0 0 0 / 25%) !important; */
    border: none !important;
  }

  .semantic-date-input .datepicker-width{
    border: none !important;
  }

  .ui.form .form-container .fields .wide.field{
    margin-bottom: 12px;
  }

  .ui.form .form-container .grey-outline{
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .pricing-table.ui.table thead th{
    background-color: transparent;
  }

  .pricing-table.ui.table:not(.unstackable) tr{
    box-shadow: none !important;
  }

  .startapp .ui.form:not(.unstackable) .fields:not(.unstackable)>.eight.wide.field input[type=text],
  .startapp .ui.form:not(.unstackable) .fields:not(.unstackable)>.eight.wide.field input[type=tel]{
    padding-left: 14px !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  }

  .startapp .corp-info-section .ui.form .field>label,
  .startapp .dba-info-section .ui.form .field>label,
  .startapp .bank-info-section .ui.form .field>label,
  .startapp .owner-info-section .ui.form .field>label,
  .startapp .business-info-section .ui.form .field>label,
  .startapp .transaction-info-section .ui.form .field>label,
  .startapp .equipment-info-section .ui.form .field>label
   {
    display: none !important;
  }
  .startapp .corp-info-section .ui.form .field>label.show-label,
  .startapp .dba-info-section .ui.form .field>label.show-label,
  .startapp .bank-info-section .ui.form .field>label.show-label,
  .startapp .owner-info-section .ui.form .field>label.show-label,
  .startapp .business-info-section .ui.form .field>label.show-label,
  .startapp .transaction-info-section .ui.form .field>label.show-label,
  .startapp .equipment-info-section .ui.form .field>label.show-label
   {
    display: block !important;
  }

  .startapp .form-container .ui.form .field input[type='text']::placeholder,
  .startapp .form-container .ui.form .field input[type='text']::-webkit-input-placeholder{
    opacity: 1 !important;
    color: #000000;
  }

  .fields label {
    font-size: 14px !important;
    font-weight: normal !important;
  }

  .billing-field .input-percent{
    width: 26px !important;
    right: -3px;
    border-radius: 0px 5px 5px 0px;
  }

  .input-percent{
    height: 50px;
    display: inline-block;
    background: #2B7FDA;
  }

  .startapp .ui.form:not(.unstackable) .fields:not(.unstackable)>.eight.wide.field .input-dollar+input[type=text],
  .startapp .ui.form .input-dollar+input[type=text],
  .startapp .ui.form:not(.unstackable) .fields:not(.unstackable)>.eight.wide.field .input-dollar+input[type=tel],
  .startapp .ui.form .input-dollar+input[type=tel] {
    padding-left: 38px !important;
  }

  .ui.form .fields.grid-align-equip{
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .mobile-table tbody td {
    max-width: 118px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .mobile-table-componnet {
    min-height: calc(100vh - 120px);
    max-height: 100%;
    display: flex;
    flex-direction: column;
  }
  .profile-name {
    background: #2B7FDA;
    color: white;
    padding: 15px 5px;
    margin-left: -30px;
    width: 300px;
    display: flex;
  }
  span.circle-name {
    width: 45px;
    padding: 5px;
    margin-left: 20px;
    border: 1px solid white;
    color: #2B7FDA;
    border-radius: 50%;
    background-color: white;
    text-align: center;
    padding-top: 8px;
  }
  span.ham-name {
    color: white;
    background-color: #2B7FDA;
    padding: 5px;
    width: 20px;
  }
  .appid-order {
    color: #2B7FDA;
    padding: 10px;
    display: block;
  }
  /* .ui.checkbox .box:before, .ui.checkbox label:before{
    background-color: #D9D9D9;
    border: 1px solid #3F3F3F;
  } */

  .blue-section{
    display: none !important;
  }


    .ui.inverted.blue.button, .ui.inverted.blue.buttons .button {
      left: 0px;
      background: #E1E2E2;
      font-weight: 700px !important;
      border-radius: 10px !important;
      margin-right: 10px;
      align-items: center;
    }

  .ui.default.dropdown:not(.button)>.text, .ui.dropdown:not(.button)>.default.text{
    color: #000;
  }

  .mob-sent {
    left: 90px;
    bottom: 18px;
    width: 265px;
    margin: 0 auto !important;
    display: flex !important;
    justify-content: center;
 }

 .mob-update {
  width: 10px;
  font-size: small !important;
 }

 .ui.form .field>label.top-content-label,
 .ui.form .field>label.mob-startapp-label-none{
  display: none !important;
 }

  .ui.radio.checkbox .box:before, .ui.radio.checkbox label:before{
    border: 1px solid #8E8E93;
    background-color: transparent;
  }

  .ui.checkbox.checked .box:before, .ui.checkbox.checked label:before{
    border-color: #2B7FDA;
  }

  .ui.checkbox.checked .box:after, .ui.checkbox.checked label:after{
    background-color: #2B7FDA;
    opacity: 1 !important;
  }

  .ui.checkbox .box:before, .ui.checkbox label:before{
    border: 1px solid #8E8E93;
    background-color: transparent;
  }

  .ui.checkbox input:checked~.box:before, .ui.checkbox input:checked~label:before{
    background-color: transparent;
  }

  .ui.checkbox input:checked~.box:after, .ui.checkbox input:checked~label:after{
    color: #2B7FDA;
    background: transparent;
  }

  .ui.radio.checkbox input:checked ~ .box:after,
  .ui.radio.checkbox input:checked ~ label:after {
    background-color: #2b7fda !important;
  }

  .pricing-table.ui.table:not(.unstackable) tbody tr,
  .other-table.ui.table:not(.unstackable) tbody tr{
    display: grid !important;
    grid-template-columns: 50% 50%;
  }

  .pricing-table.ui.table:not(.unstackable) tr>td, .ui.table:not(.unstackable) tr>th,
  .other-table.ui.table:not(.unstackable) tr>td, .ui.table:not(.unstackable) tr>th{
    padding: .25em 0px !important;
  }

  .other-table.ui.table thead th{
    background-color: #D9D9D9;
  }

  .business-info-section .ui.form .fields{
    justify-content: start;
  }

  .business-info-section .ui.form .fields div.ui{
    margin-right: 1rem;
  }

  .billing-month-wrapper{
    display: grid;
    grid-template-columns: 49% 49%;
    justify-content: space-between;
  }
  
  .billing-month-wrapper .billing-field{
    min-width: 100%;
  }

  .ui.form .inline.field>:first-child, .ui.form .inline.fields .field>:first-child{
    margin-bottom: 12px;
    font-size: 14px;
  }

  .seasonal-month-wrapper {
    justify-content: flex-start !important;
  }

  /* .transaction-fields .input-dollar{
    right: 0;
  }
  
  .transaction-fields .input-percent{
    right: 0;
  } */

  .startapp .ui.form.transaction-fields:not(.unstackable) .fields:not(.unstackable)>.eight.wide.field .input-dollar+input[type=text], .startapp .ui.form.transaction-fields .input-dollar+input[type=text],
  .startapp .ui.form.transaction-fields:not(.unstackable) .fields:not(.unstackable)>.eight.wide.field .input-dollar+input[type=tel], .startapp .ui.form.transaction-fields .input-dollar+input[type=tel]{
    width: 100% !important;
  }

  /* .ui.form .form-container .transaction-fields .fields .wide.field{
    display: grid;
    grid-template-columns: 65% 35%;
  } */

  .startapp .ui.form.transaction-fields:not(.unstackable) .fields:not(.unstackable)>.eight.wide.field .input-dollar+input[type=text],
  .startapp .ui.form.transaction-fields .input-dollar+input[type=text]
  .startapp .ui.form.transaction-fields:not(.unstackable) .fields:not(.unstackable)>.eight.wide.field .input-dollar+input[type=tel],
  .startapp .ui.form.transaction-fields .input-dollar+input[type=tel] {
    padding-left: 40px !important;
  }

  input[role=combobox] {
    box-shadow: none !important;
  }

  .ui.form .field>label.d-none-sm{
    display: none !important;
  }
  .filter-header-mob{
    border: 1px solid white;
    border-radius: 5px;
    padding: 4px;
  }
  .mob-name-role {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    padding-left: 20px;
    padding-top: 2px;
  }

  .ui.button.mob-save-button{
    min-width: 134px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    height: 48px;
    margin-right: 8px;
  }

  .ui.form .pricing-table .input-dollar+input[type=text],
  .ui.form .pricing-table .input-dollar+input[type=tel]{
    padding-left: 40px !important;
  }

  .ui.table:not(.unstackable) td:first-child{
    font-weight: normal;
  }

  .pricing-table.ui.table.qual:not(.unstackable) tbody tr,
  .pricing-table.ui.table.qual:not(.unstackable) thead tr{
    display: grid !important;
    grid-template-columns: 30% 33% 33%;
    justify-content: space-between;
    align-items: center;
  }

  .pricing-table.ui.table  .input-percent{
    color: white;
  }

  .button-containers{
    display: flex ;
    justify-content: space-between;
  }
  .button.ui.blue.button.send-email-buttons{
    margin-top: 16px;
    margin: 16px 14px;
  }
  
  .personal-msg{
    color: white !important;
   }
    
  .left-heading{
    font-size: 14px;
  }

  .drop-container{
    margin: 10px 0 20px;
    position: relative;
    height: 75px;
    display: flex;
  }

  .drop-container .docupload-icon{
    margin: 0;
    right: 10px;
    top: 15px;
    background: transparent url("../../../Images/upload-icon2.svg") no-repeat center;
  }

  .drop-container .drop-message{
    margin: 0;
  }

  .drop-container .drop-message .dropzone{
    border: none;
  }
  .mobile-tabs-slider {
    order:3;
    width: 100%;
    padding: 0 8px;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  .mob-audit-filter {
    flex-direction: column;
    order:1
  }
  .mob-audit-filter label{
    display: none;
  }

  .mob-audit-filter .field{
    width: 95%;
    margin: auto;
  }

  .mob-audit-filter .field .ui.input{
    padding: 8px;
    width: 100%;
  }

  .mob-audit-filter .field .ui.icon.input>input{
    width: 100%;
    height: 50px;
    padding-left: 14px!important;
    box-shadow: 0 4px 4px rgba(0,0,0,.25)!important;
    border-radius: 5px;
  }
  .userslist-container {
    min-height: 80vh;
  }
  .userslist-container > .listapp-body-content {
    min-height:calc(50vh - 50px);
  }
  .userslist-container .button-container button{
    height: 50px;
    min-width: 150px;
    border-radius: 10px;
  }
  .mob-audit-filter .field .ui.icon.input>i.icon{
    right: 10px;
  }

  .user-data-model{
    height: 100vh;
    width: 100vw;
  }

  .mob-audit-heading-filter {
    flex-direction: column;
  }
  .mobile-tabs-slider > button.active-btn {
    background-color: white !important;
    color: #2B7FDA !important;
    border: 0 !important;
    border-radius: 0;
    border-bottom: 5px solid #2B7FDA !important;
    width:45%;
  }
  .mobile-tabs-slider > button.not-active-btn {
    background-color: white !important;
    color: gray !important;
    border: 0 !important;
    border-radius: 0;
    border-bottom: 5px solid gray !important;
    width: 45%;
  }

  .listapp-body-content{
    padding: 0;
    margin: 0;
    position: relative;
  }

  .heading{
    margin: 0;
    margin-top: 1rem;
    padding: 8px;
  }

  .control_fields{
    margin-right: 9rem !important;
  }
  
  .checkbox-inline{
    margin-right: 9rem !important;
  }

  .mobile-menu-sidebar .mobile-menu-items a:hover{
    color: white;
    background-color: #2B7FDA;
  }

  .user-data-model-table, .user-data-model-table{
    margin-bottom: 1rem;
  }

  .user-data-model-table  + .ui.blue.button{
    margin-bottom: 80px;
    float: right;
    margin: 0 8px 80px;
    height: 50px;
    min-width: 150px;
  }

  button.ui.blue.inverted.disabled.button.save-button.startapp-send-button{
    margin-right: 10px !important;
    margin-top: 13px !important;
  }

  .radio_risk_level{
    margin-right: 80px !important;
  }
  .sixteen.wide.field.margin-bottom-30.mob-upload {
    margin-left: -8rem;
  }
  .link-details-field-container {
    overflow-wrap: break-word;
  }
  .link-details-field-container > div:nth-child(2) {
    max-width: 10rem;
  }
  .ui.modal.transition.visible.active {
    box-shadow: none !important;
  }
  .ui.modal .actions > .button {
    margin-left: 0;
    margin-bottom: 0.5rem;
  }
  .ui.modal.active.visible > .actions {
    padding: 0.7rem 0.7rem 0.3rem 0.3rem !important;
    display: flex;
    justify-content: space-evenly;
  }

  button.startapp-action-button>.ui.inverted.blue{
    margin-top: 13px !important;

  }

  .table-actions-wrapper {
    background-color: transparent !important;
  }

  .vertical-grid{
    display: grid !important;
    grid-template-columns: 32% 32% 32%;
    align-items: end;
  }

  .sidebar-search {
    border:1px solid black;
    border-radius: 3px;
    margin-top: 10px;
    width: 100%;
    height: 30px;
    padding: 10px;
  }
  .search-buttons {
    margin: 7px;
    display: flex;
    justify-content: space-between;
  }
  .search-buttons button {
    min-width: 100px;
    padding:5px;
    color: white;
    background-color: #2B7FDA;
    border-radius: 5px;
    font-size: 1rem;
    border:1px solid #2B7FDA;
    font-family: Montserrat;
  }

  input.sidebar-search{
    width: 100%;
    height: 40px;
    border-radius: 10px;
    border: 2px solid #979797;
  }
  
  .table-heading-container h3 {
    margin-bottom: 0;
  }
  .dropdown-whole{
    display: block;
  }

  .field-box-dropdown{
    width: 100%;
  }

  p.value{
    font-size: 11px;
  }

  p.value-edit{
    font-size: 10px;
    margin-right: 2px;
  }

  p.value-delete{
    font-size: 10px;
  }

  p.value-update{
    font-size: 9px;
    margin-right: 2px;
  }

  p.value-cancel{
    font-size: 9px;
  }

  .fill-width{
    width: 78%;
  }

  .Value-box.mobile-value-box{
    display: block;
  }

  button.ui.blue.button.mobile-add-field,
  button.ui.blue.disabled.button.mobile-add-field{
    margin-top: 10px !important;
    margin-left: 40px !important;
  }

  .overlay-layer {
    height: 110vh;
    position: fixed;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.80);
    top: 0;
    right: 0;
  }

  .table-actions-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5) !important;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .table-actions-wrapper .table-actions-mob {
    border-radius: 13px;
    width:100%;
    max-width: 350px;
    background-color: white;
    color:black;
    position: relative;
    margin: 0;
  }

  .table-actions-wrapper .table-actions-mob > button {
    border-bottom:0.5px solid rgba(141, 141, 141, 0.5);
    box-sizing: border-box;
    padding:10px 20px;
    text-align: center;
    width: 100%;
    max-width: 350px;
    background-color:white;
    font-size: 17px;
  }

  .table-actions-wrapper .table-actions-mob .top-radius {
    border-radius: 13px 13px 0 0;
  }

  .table-actions-wrapper .table-actions-mob .bottom-radius {
    border-radius: 0 0 13px 13px;
    border: none !important;
  }

  .heading-center{
    font-size: 18px;
  }

  span.heading-center.mobile-user-list {
    display: none;
  }

  .mobile-table-list {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 60vh;
    margin-top: 12px;
  }

  .body-content.mobile-user-list-body{
    display: none;
  }

  button.ui.button.cancel-mob-field{
    margin-top: 10px;
  }

  .ant-pagination{
    padding: 6px;
  }

  .search-mob{
  display: inline-block;
  text-align: center;
  width: 80%;
  }

  .ant-input-group-wrapper{
    width: 80% !important;
  }

  .active-mobile {
    background-color: #2B7FDA;
    color: white !important;
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    border-left: 5px solid #D9D9D9;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .mobile-form-section {
    overflow: hidden;
    transition: all 1s ease-in-out;

  }

  .mobile-form-section-open {
    max-height: 400vh;

  }

  .mobile-form-section-closed {
    max-height: 0px;
  }

  .applist-container
  {
  display: flex;
  flex-direction: column;
  }

  .applist-container .bulkupdate-action {
    width: 50%;
   }

}

.startapp.ui.form .field .placeholder-visible::placeholder,
.startapp.ui.form .field .placeholder-visible::-webkit-input-placeholder{
  visibility: visible;
}

.startapp.ui.form .field .placeholder-visible-child input,
.startapp.ui.form .field .placeholder-visible-child input::-webkit-input-placeholder{
  visibility: visible;
}

.billing-month-wrapper{
  grid-template-columns: 33% 33% 33%;
}
.button-wrapper {
  width:100%;
  margin: 0 auto;  
  display: flex;
  justify-content: center;
}

.block-button {
  left: 90px;
  bottom: 10px;
  width: 210px;
}

@media only screen and (max-width: 767px),
only screen and (max-height: 500px) and (orientation: landscape) {
  .save-button {
    font-size: 14px !important;
    min-width: 100px;
  }
}
